import { useAuth0 } from '@auth0/auth0-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { Icon } from '../../../Icon'
import { deleteDefaultSubscriptionId } from '../../../SubscriptionSelector/helpers'
import { NavigationItem } from './NavigationItem'
import Styles from './styles.module.scss'

export const Mobile = (): JSX.Element => {
  const { logout } = useAuth0()
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const clickHandler = (path: string): void => {
    setShow(false)
    navigate(path)
  }

  const handleLogout = (): void => {
    deleteDefaultSubscriptionId()
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <div>
      <div className={Styles.icons}>
        <Icon className={Styles.icon} name={show ? 'cross' : 'menu'} onClick={(): void => setShow((prev) => !prev)} />
      </div>

      {show && (
        <div className={Styles.menu}>
          <NavigationItem
            onClick={clickHandler}
            path={routes[ROUTE_NAMES.DASHBOARD]}
            name={t('Home')}
            isActiveOnChildRoutes={false}
          />
          <NavigationItem
            onClick={clickHandler}
            path={routes[ROUTE_NAMES.MY_LESSONS]}
            name={t('My lessons')}
            isActiveOnChildRoutes
          />
          <NavigationItem
            onClick={clickHandler}
            path={routes[ROUTE_NAMES.LEARN_MORE]}
            name={t('Learn more')}
            isActiveOnChildRoutes
          />
          <NavigationItem
            onClick={clickHandler}
            path={routes[ROUTE_NAMES.UPCOMING]}
            name="Upcoming"
            isActiveOnChildRoutes
          />
          <NavigationItem
            onClick={clickHandler}
            path={routes[ROUTE_NAMES.SUPPORT]}
            name="Support/FAQ"
            isActiveOnChildRoutes
          />
          <NavigationItem
            onClick={clickHandler}
            path={routes[ROUTE_NAMES.PROFILE]}
            name={t('Profile')}
            isActiveOnChildRoutes
          />
          <div className={Styles.logoutWrapper} onClick={handleLogout}>
            <p>{t('Log out')}</p>
            <Icon className={Styles.logout} name="logout" />
          </div>
        </div>
      )}
    </div>
  )
}
