import React, { useEffect, useMemo, useState } from 'react'

import { SuspenseLoading } from '../../Components'
import { SubscriptionSelector } from '../../Components/SubscriptionSelector'
import { getDefaultSubscriptionId } from '../../Components/SubscriptionSelector/helpers'
import { useGetUserQuery } from '../../Lib/graphql'
import { ICurrentUserContext } from './interfaces'

export const CurrentUserContext = React.createContext<ICurrentUserContext>({
  firstName: undefined,
  lastName: undefined,
  fullName: undefined,
  subscriptionId: null,
  setSubscriptionId: () => {},
})

export const CurrentUserProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()

  const [subscriptionId, setSubscriptionId] = useState<string | null>(getDefaultSubscriptionId())

  const { data, loading } = useGetUserQuery()

  const { children } = props

  useEffect(() => {
    const user = data?.users[0]
    if (!user) return
    setFirstName(user.first_name || '')
    setLastName(user.last_name || '')
  }, [data])

  const fullName = [firstName, lastName].filter((part) => part !== '').join(' ')

  const options = useMemo(() => {
    return {
      firstName,
      lastName,
      fullName,
      subscriptionId,
      setSubscriptionId,
    }
  }, [firstName, fullName, lastName, subscriptionId])

  if (!subscriptionId) {
    return (
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SubscriptionSelector onSelect={setSubscriptionId} />
      </div>
    )
  }

  if (loading) return <SuspenseLoading />
  return <CurrentUserContext.Provider value={options}>{children}</CurrentUserContext.Provider>
}
