import './Lib/i18n'
import 'react-simple-flex-grid/lib/main.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useCallback, useEffect, useState } from 'react'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { SuspenseLoading } from './Components'
import { getJwtToken, saveJwtToken } from './helpers'
import { AuthorizedLayout, UnauthorizedLayout } from './Layouts'
import { authorizedClient, unauthorizedClient } from './Lib/apolloClient'
import { MediaQueriesProvider } from './Providers/MediaQueriesProvider'

const LoginOrMain: React.FC = (): JSX.Element => {
  const { isAuthenticated } = useAuth0()
  return isAuthenticated ? <AuthorizedLayout /> : <UnauthorizedLayout />
}

export const Wrapper: React.FC = (): JSX.Element => {
  const { isAuthenticated, logout, getIdTokenClaims } = useAuth0()
  const [jwtToken, setJwtToken] = useState<string | null>(getJwtToken())

  const initializeJwtToken = useCallback(async (): Promise<void> => {
    try {
      const idToken = await getIdTokenClaims()
      if (idToken?.__raw) {
        const token = idToken.__raw
        setJwtToken(token)
        saveJwtToken(token)
      } else console.error('Could not extract property __raw from idToken')
    } catch (error) {
      console.error('could not get token silently because ', error)
    }
  }, [getIdTokenClaims])

  useEffect(() => {
    initializeJwtToken()
  }, [initializeJwtToken])

  const { link } = createNetworkStatusNotifier()

  return (
    <ApolloProvider
      client={isAuthenticated && !!jwtToken ? authorizedClient(jwtToken, link, logout) : unauthorizedClient()}
    >
      <MediaQueriesProvider>
        <ToastContainer position="bottom-left" newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable />
        <Router>
          <LoginOrMain />
        </Router>
      </MediaQueriesProvider>
    </ApolloProvider>
  )
}

const App: React.FC = (): JSX.Element => {
  const { isLoading } = useAuth0()
  return (
    <div style={{ height: '100%' }} id="AppWrapper">
      {isLoading ? <SuspenseLoading /> : <Wrapper />}
    </div>
  )
}
export default App
