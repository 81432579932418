import { useCallback, useState } from 'react'

import { useGetNewsQuery } from '../../Lib/graphql'

const UPCOMING_STORAGE_KEY = 'UPCOMING'

export const useNewUpcoming = (): { readAll: () => void; hasUnreadArticles: boolean } => {
  const [hasUnreadArticles, setHasUnreadArticles] = useState<boolean>(false)

  const { data } = useGetNewsQuery({
    onCompleted: (response) => {
      const articleIds = response.news_articles_view?.map((art) => art.id) || []
      const historyIds: string[] = JSON.parse(window.localStorage.getItem(UPCOMING_STORAGE_KEY) || '[]')
      const hasReadAll = articleIds.every((item) => historyIds.includes(item))
      setHasUnreadArticles(!hasReadAll)
    },
  })

  const readAll = useCallback(() => {
    if (!hasUnreadArticles) return

    const articleIds = data?.news_articles_view?.map((art) => art.id) || []
    window.localStorage.setItem(UPCOMING_STORAGE_KEY, JSON.stringify(articleIds))
    setHasUnreadArticles(false)
  }, [data?.news_articles_view, hasUnreadArticles])

  return { hasUnreadArticles, readAll }
}
