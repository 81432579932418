export enum ROUTE_NAMES {
  DASHBOARD,
  MY_LESSONS,
  MY_LESSONS_REPEAT_LESSON,
  LEARN_MORE,
  LEARN_MORE_TASKS,
  LEARN_MORE_GOOD_TO_KNOW,
  LEARN_MORE_RECITATIONS,
  LEARN_MORE_SMEEKBEDES,
  LEARN_MORE_BONUS_VIDEOS,
  LEARN_MORE_KENNISTESTEN,
  LEARN_MORE_KENNISTESTEN_VIDEO,
  LESSON,
  COMMUNITY,
  COMMUNITY_REFLECTION,
  COMMUNITY_AGENDA,
  COMMUNITY_RESERVATION,
  SUPPORT,
  PROFILE,
  UPCOMING,
}

export type TRoutes = {
  [route in ROUTE_NAMES]: string
}
