import { ROUTE_NAMES, TRoutes } from './interfaces'

export const routes: TRoutes = {
  [ROUTE_NAMES.DASHBOARD]: `/`,
  [ROUTE_NAMES.MY_LESSONS]: `/mijn-lessen`,
  [ROUTE_NAMES.LESSON]: `/mijn-lessen/:id`,
  [ROUTE_NAMES.MY_LESSONS_REPEAT_LESSON]: `/mijn-lessen/herhaling`,
  [ROUTE_NAMES.LEARN_MORE]: `/leer-meer`,
  [ROUTE_NAMES.LEARN_MORE_TASKS]: `/leer-meer/opdrachten`,
  [ROUTE_NAMES.LEARN_MORE_GOOD_TO_KNOW]: `/leer-meer/good-to-know`,
  [ROUTE_NAMES.LEARN_MORE_RECITATIONS]: `/leer-meer/recitaties`,
  [ROUTE_NAMES.LEARN_MORE_SMEEKBEDES]: `/leer-meer/smeekbedes`,
  [ROUTE_NAMES.LEARN_MORE_BONUS_VIDEOS]: `/leer-meer/bonus-videos`,
  [ROUTE_NAMES.LEARN_MORE_KENNISTESTEN]: `/leer-meer/kennistesten`,
  [ROUTE_NAMES.LEARN_MORE_KENNISTESTEN_VIDEO]: `/leer-meer/kennistesten/video`,
  [ROUTE_NAMES.COMMUNITY]: `/community`,
  [ROUTE_NAMES.UPCOMING]: `/upcoming`,
  [ROUTE_NAMES.COMMUNITY_REFLECTION]: `/community/reflectie`,
  [ROUTE_NAMES.COMMUNITY_AGENDA]: `/community/agenda`,
  [ROUTE_NAMES.COMMUNITY_RESERVATION]: `/community/reservering`,
  [ROUTE_NAMES.SUPPORT]: `/support`,
  [ROUTE_NAMES.PROFILE]: `/profiel`,
}
