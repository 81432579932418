import React from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { Typography } from '../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const NavigationItem: React.FC<IProps> = ({
  signOn = false,
  isActiveOnChildRoutes,
  path,
  name,
}): JSX.Element => {
  const navigate = useNavigate()
  const isActive = Boolean(useMatch({ path, caseSensitive: false, end: !isActiveOnChildRoutes }))

  const classNames = ((): string => {
    const classes: string[] = [Styles.navigationItem]

    if (isActive) classes.push(Styles.isActive)
    if (signOn) classes.push(Styles.signOn)
    return classes.join(' ')
  })()

  return (
    <div className={classNames} onClick={(): void => navigate(path)}>
      <Typography component="body1">{name}</Typography>
    </div>
  )
}
