import React from 'react'
import { useMatch } from 'react-router-dom'

import Styles from './styles.module.scss'

interface IProps {
  path: string
  name: string
  isActiveOnChildRoutes: boolean
  onClick: (path: string) => void
}

export const NavigationItem: React.FC<IProps> = ({ onClick, name, path, isActiveOnChildRoutes }): JSX.Element => {
  const isActive = Boolean(useMatch({ path, caseSensitive: false, end: !isActiveOnChildRoutes }))

  const handleClick = (): void => onClick(path)

  return (
    <div onClick={handleClick} className={`${Styles.navigationItem} ${isActive && Styles.active}`}>
      {name}
    </div>
  )
}
