import React from 'react'

import { IProps } from './interface'

const defaulticonsize = '1.125em'

export const Icon: React.FC<IProps> = ({
  height = defaulticonsize,
  name,
  color,
  width = defaulticonsize,
  ...svgProps
}): JSX.Element | null => {
  const symbolId = `#icon-${name}`
  return (
    <svg {...svgProps} width={width} height={height} aria-hidden="true" fill={color}>
      <use xlinkHref={symbolId} fill={color} color={color} />
    </svg>
  )
}
