import { useAuth0 } from '@auth0/auth0-react'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import BookHolder from '../../../Assets/Logo/book_holder.png'
import Logo from '../../../Assets/Logo/logo.png'
import { useNewUpcoming } from '../../../Hooks/useNewUpcoming'
import { ROUTE_NAMES } from '../../../Layouts/Authorized/interfaces'
import { routes } from '../../../Layouts/Authorized/routes'
import { MediaQueriesContext } from '../../../Providers/MediaQueriesProvider'
import { Button } from '../../FormComponents/Button'
import { LazyImage } from '../../LazyImage'
import { deleteDefaultSubscriptionId } from '../../SubscriptionSelector/helpers'
import { Mobile } from './Mobile'
import { NavigationItem } from './NavigationItem'
import Styles from './styles.module.scss'

export const Header = (): JSX.Element => {
  const { t } = useTranslation()
  const { logout } = useAuth0()
  const navigate = useNavigate()
  const { isMDScreen } = useContext(MediaQueriesContext)
  const isMobile = !isMDScreen

  const handleLogout = (): void => {
    deleteDefaultSubscriptionId()
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  const { hasUnreadArticles } = useNewUpcoming()

  return (
    <div className={Styles.header}>
      <div className={Styles.logoContainer} onClick={(): void => navigate(routes[ROUTE_NAMES.DASHBOARD])}>
        <LazyImage src={Logo} alt="logo" width={149 / (isMobile ? 2 : 1)} height={63 / (isMobile ? 2 : 1)} />
        <LazyImage src={BookHolder} alt="logo" width={96 / (isMobile ? 2 : 1)} height={65 / (isMobile ? 2 : 1)} />
      </div>

      {isMobile ? (
        <Mobile />
      ) : (
        <React.Fragment>
          <div className={Styles.navigation}>
            <NavigationItem path={routes[ROUTE_NAMES.DASHBOARD]} name={t('Home')} isActiveOnChildRoutes={false} />
            <NavigationItem path={routes[ROUTE_NAMES.MY_LESSONS]} name={t('My lessons')} isActiveOnChildRoutes />
            <NavigationItem path={routes[ROUTE_NAMES.LEARN_MORE]} name={t('Learn more')} isActiveOnChildRoutes />
            <NavigationItem
              path={routes[ROUTE_NAMES.UPCOMING]}
              name="Upcoming"
              signOn={hasUnreadArticles}
              isActiveOnChildRoutes
            />
            <NavigationItem path={routes[ROUTE_NAMES.SUPPORT]} name="Support/FAQ" isActiveOnChildRoutes />
          </div>

          <div className={Styles.buttons}>
            <Button onClick={(): void => navigate(routes[ROUTE_NAMES.PROFILE])}>{t('My profile')}</Button>
            <Button className={Styles.logout} onClick={handleLogout}>
              {t('Log out')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
