import React from 'react'

import { TProps } from './interfaces'
import Styles from './styles.module.scss'

export const Typography: React.FC<TProps> = ({
  variant = 'default',
  component,
  className,
  color,
  children,
  ...props
}): JSX.Element => {
  // Defining the HTML tag that the component will support
  const variantsMapping: { [key: string]: keyof JSX.IntrinsicElements } = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    body1: 'p',
  }

  const Component = variantsMapping[component]
  const classNames: string[] = [Styles[variant]]
  if (className) classNames.push(className)

  return (
    <Component {...(color && { style: { color } })} className={classNames.join(' ')} {...props}>
      {children}
    </Component>
  )
}
