import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Button: React.FC<IProps> = ({
  id,
  variant = 'primary',
  fullWidth,
  loading,
  children,
  className,
  ...otherProps
}): JSX.Element => {
  const classNames = ((): string => {
    const list: string[] = [Styles.button]
    if (className) list.push(className)
    if (variant) list.push(Styles[variant])
    if (fullWidth) list.push(Styles.fullWidth)
    if (loading) list.push(Styles.loading)

    return list.join(' ')
  })()

  return (
    <button id={id} {...otherProps} className={classNames} type="button">
      {children}
    </button>
  )
}
