export const generateHeaders = (): Record<string, string> => {
  const conditionalHeaders: Record<string, string> = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (process.env.NODE_ENV !== 'production') conditionalHeaders['X-Requested-With'] = 'XMLHttpRequest'
  return conditionalHeaders
}

interface IProps {
  token?: string | null
  withAuthorization: boolean
}

export const generateApolloClientHeaders = ({ token, withAuthorization }: IProps): Record<string, string> => {
  const sharedHeaders = {
    Accept: 'application/json; api-version=3',
  }

  if (!withAuthorization || !token) return sharedHeaders

  const bearerToken = `Bearer ${token}`
  return {
    ...sharedHeaders,
    authorization: bearerToken,
  }
}
