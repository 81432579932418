import { useAuth0 } from '@auth0/auth0-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateUserChildMutation, useGetSubscriptionsQuery } from '../../Lib/graphql'
import { Button } from '../FormComponents/Button'
import { Typography } from '../Typography'
import { deleteDefaultSubscriptionId, getDefaultSubscriptionId, setDefaultSubscriptionId } from './helpers'
import { TSubscription } from './interfaces'
import Styles from './styles.module.scss'

export const SubscriptionSelector: React.FC<{ onSelect: (id: string) => void }> = ({
  onSelect,
}): JSX.Element | null => {
  const { t } = useTranslation()
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null)
  const [profileName, setProfileName] = useState<string>('')
  const { logout } = useAuth0()

  const handleLogout = (): void => {
    logout({ logoutParams: { returnTo: window.location.origin }, clientId: 'pOmHenCzm9kazOmltRauam4k3e8fr2ZJ' })
  }

  const { data } = useGetSubscriptionsQuery()
  const subscriptions = useMemo(() => data?.users[0]?.subscriptions || [], [data?.users])

  const processSelectedSubscription = useCallback(
    (id: string): void => {
      const subscription = subscriptions.find((sub) => sub.id === id)

      //   In case the subscription isn't linked to the user de-link it.
      if (!subscription) {
        deleteDefaultSubscriptionId()
        setSubscriptionId(null)
        return
      }

      // Save as default in sessionStorage
      setDefaultSubscriptionId(id)
      onSelect(id)
    },
    [onSelect, subscriptions]
  )

  useEffect(() => {
    if (!subscriptions || subscriptions.length === 0) return
    const storedSubscriptionId = getDefaultSubscriptionId()
    if (storedSubscriptionId) processSelectedSubscription(storedSubscriptionId)
  }, [processSelectedSubscription, subscriptions])

  const handleClick = (subscription: TSubscription): void => {
    const userChildren = subscription.users_children

    if (userChildren.length === 0) setSubscriptionId(subscription.id)
    else processSelectedSubscription(subscription.id)
  }

  const [createUserChild, { loading }] = useCreateUserChildMutation()

  const createProfile = (): void => {
    if (!profileName || !subscriptionId) return

    createUserChild({
      variables: {
        input: {
          name: `${profileName}-${subscriptionId}`,
          username: profileName,
          subscription_id: subscriptionId,
        },
      },
    })
      .then((response) => {
        const result = response.data?.insert_users_children_one?.id
        if (result) processSelectedSubscription(subscriptionId)
        else window.alert('Er ging wat fout bij het aanmaken van een profiel, neem contact met de klantenservice')
      })
      .catch(console.error)
  }

  if (!data || loading) return null

  return (
    <div className={Styles.container}>
      {subscriptions.length === 0 && (
        <div>
          <Typography component="h3" className={Styles.label}>
            Schaf een cursus aan om verder te gaan
          </Typography>
          <div className={Styles.buttons}>
            <Button
              variant="primary"
              onClick={(): void => {
                window.open(
                  'https://www.islamstudies.nl/studies/de-koran-van-kaft-tot-kaft-online-studie/#prijstabel',
                  '_blank'
                )
              }}
            >
              Aanschaffen
            </Button>
            <Button variant="secondary" onClick={handleLogout}>
              {t('Log out')}
            </Button>
          </div>
        </div>
      )}
      <div className={Styles.subscriptionSelector}>
        {subscriptions?.map((sub) => {
          const hasProfile = sub.users_children.length > 0

          return (
            <div key={sub.id}>
              <div className={Styles.subscription} onClick={(): void => handleClick(sub)}>
                <Typography className={Styles.name} component="body1">
                  {sub.course?.name}
                </Typography>
              </div>
              <div className={Styles.footer}>
                {!hasProfile && (
                  <div className={Styles.createProfile} onClick={(): void => setSubscriptionId(sub.id)}>
                    <span>Profiel aanmaken</span>
                  </div>
                )}
                {hasProfile && (
                  <div className={Styles.continue} onClick={(): void => handleClick(sub)}>
                    <span>Ga verder</span>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>

      {subscriptionId && (
        <div className={Styles.form}>
          <div className={Styles.field}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="name">Profiel naam</label>
            <input id="name" onChange={(event): void => setProfileName(event.currentTarget.value)} />
          </div>

          <Button fullWidth disabled={!profileName} loading={loading} onClick={createProfile}>
            Opslaan
          </Button>
        </div>
      )}
    </div>
  )
}
