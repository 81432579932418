const DEFAULT_SUBSCRIPTION_ID_KEY = 'defaultSubscriptionId'

export const getDefaultSubscriptionId = (): string | null => {
  return window.localStorage.getItem(DEFAULT_SUBSCRIPTION_ID_KEY)
}

export const setDefaultSubscriptionId = (subscriptionId: string): void => {
  window.localStorage.setItem(DEFAULT_SUBSCRIPTION_ID_KEY, subscriptionId)
}

export const deleteDefaultSubscriptionId = (): void => {
  window.localStorage.removeItem(DEFAULT_SUBSCRIPTION_ID_KEY)
}
