import { useAuth0 } from '@auth0/auth0-react'
import { isPast } from 'date-fns'
import React, { lazy, Suspense, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { SuspenseLoading } from '../../Components'
import { Header } from '../../Components/PageContainer/Header'
import SessionGuard from '../../Components/SessionGuard'
import { useGetSubscriptionStatusQuery } from '../../Lib/graphql'
import PageNotFound from '../../Pages/PageNotFound'
import { CurrentUserContext, CurrentUserProvider } from '../../Providers/CurrentUserProvider'
import { ROUTE_NAMES } from './interfaces'
import { routes } from './routes'
import Styles from './styles.module.scss'

const Home = lazy(() => import(/* webpackChunkName: "Home" */ '../../Pages/Home'))
const MyLessons = lazy(() => import(/* webpackChunkName: "MyLessons" */ '../../Pages/MyLessons'))
const LearnMore = lazy(() => import(/* webpackChunkName: "LearnMore" */ '../../Pages/LearnMore'))
const Renew = lazy(() => import(/* webpackChunkName: "Renew" */ '../../Pages/Renew'))
// const Community = lazy(() => import(/* webpackChunkName: "Community" */ '../../Pages/Community'))
// const Reflection = lazy(() => import(/* webpackChunkName: "Reflection" */ '../../Pages/Community/Pages/Reflection'))
// const Agenda = lazy(() => import(/* webpackChunkName: "Agenda" */ '../../Pages/Community/Pages/Agenda'))
// const Reservation = lazy(() => import(/* webpackChunkName: "Reservation" */ '../../Pages/Community/Pages/Reservation'))
const Upcoming = lazy(() => import(/* webpackChunkName: "Upcoming" */ '../../Pages/Upcoming'))
const Support = lazy(() => import(/* webpackChunkName: "Support" */ '../../Pages/Support'))
const Lesson = lazy(() => import(/* webpackChunkName: "Lesson" */ '../../Pages/MyLessons/Pages/Lesson'))
const LearnAndSearchTasks = lazy(
  () => import(/* webpackChunkName: "LearnAndSearchTasks" */ '../../Pages/LearnMore/Pages/LearnAndSearchTasks')
)
const GoodToKnow = lazy(() => import(/* webpackChunkName: "GoodToKnow" */ '../../Pages/LearnMore/Pages/GoodToKnow'))
const BonusVideos = lazy(() => import(/* webpackChunkName: "BonusVideos" */ '../../Pages/LearnMore/Pages/BonusVideos'))
const KennisTesten = lazy(
  () => import(/* webpackChunkName: "KennisTesten" */ '../../Pages/LearnMore/Pages/KennisTesten')
)
const KennisTesteVideo = lazy(
  () => import(/* webpackChunkName: "KennisTestenVideo" */ '../../Pages/LearnMore/Pages/KennisTesten/Video')
)
const Recitations = lazy(() => import(/* webpackChunkName: "Recitations" */ '../../Pages/LearnMore/Pages/Recitations'))
const Smeekbedes = lazy(() => import(/* webpackChunkName: "Smeekbedes" */ '../../Pages/LearnMore/Pages/Smeekbedes'))
const RepeatLesson = lazy(
  () => import(/* webpackChunkName: "RepeatLesson" */ '../../Pages/MyLessons/Pages/RepeatLesson')
)
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ '../../Pages/Profile'))

const Router: React.FC = (): JSX.Element => {
  const { subscriptionId } = useContext(CurrentUserContext)
  const { data } = useGetSubscriptionStatusQuery({ variables: { subscriptionId }, skip: !subscriptionId })

  const subscriptionEndsOn = data?.subscriptions_by_pk?.ends_on
  const isExpired = !!subscriptionEndsOn && isPast(new Date(subscriptionEndsOn))

  if (isExpired) {
    return (
      <TransitionGroup className={Styles.appRoutesTransitionGroup}>
        <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} classNames="fade">
          <Routes>
            <Route path="*" element={<Renew />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    )
  }

  return (
    <TransitionGroup className={Styles.appRoutesTransitionGroup}>
      <CSSTransition key={location.pathname} timeout={{ enter: 250, exit: 0 }} classNames="fade">
        <Routes>
          <Route path={routes[ROUTE_NAMES.DASHBOARD]} element={<Home />} />
          <Route path={routes[ROUTE_NAMES.MY_LESSONS]} element={<MyLessons />} />
          <Route path={routes[ROUTE_NAMES.MY_LESSONS_REPEAT_LESSON]} element={<RepeatLesson />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE]} element={<LearnMore />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_TASKS]} element={<LearnAndSearchTasks />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_GOOD_TO_KNOW]} element={<GoodToKnow />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_RECITATIONS]} element={<Recitations />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_SMEEKBEDES]} element={<Smeekbedes />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_BONUS_VIDEOS]} element={<BonusVideos />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_KENNISTESTEN]} element={<KennisTesten />} />
          <Route path={routes[ROUTE_NAMES.LEARN_MORE_KENNISTESTEN_VIDEO]} element={<KennisTesteVideo />} />
          {/* TODO: Needs to be added later on request */}
          {/* <Route path={routes[ROUTE_NAMES.COMMUNITY]} element={<Community />} /> */}
          {/* <Route path={routes[ROUTE_NAMES.COMMUNITY_REFLECTION]} element={<Reflection />} /> */}
          {/* <Route path={routes[ROUTE_NAMES.COMMUNITY_AGENDA]} element={<Agenda />} /> */}
          {/* <Route path={routes[ROUTE_NAMES.COMMUNITY_RESERVATION]} element={<Reservation />} /> */}
          <Route path={routes[ROUTE_NAMES.SUPPORT]} element={<Support />} />
          <Route path={routes[ROUTE_NAMES.UPCOMING]} element={<Upcoming />} />
          <Route path={routes[ROUTE_NAMES.LESSON]} element={<Lesson />} />
          <Route path={routes[ROUTE_NAMES.PROFILE]} element={<Profile />} />

          {/* Keep as the last path! */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}

export const AuthorizedLayout: React.FC = (): JSX.Element | null => {
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) return null

  return (
    <div className={Styles.authorizedLayout}>
      <CurrentUserProvider>
        <SessionGuard />
        <Suspense fallback={<SuspenseLoading />}>
          <Header />
          <Router />
        </Suspense>
      </CurrentUserProvider>
    </div>
  )
}
