import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetUserSessionQuery, useUpsertSessionMutation } from '../../Lib/graphql'
import { Button } from '../FormComponents/Button'
import { ModalContainer } from '../ModalContainer'
import { deleteDefaultSubscriptionId } from '../SubscriptionSelector/helpers'
import Styles from './styles.module.scss'

const SessionGuard = (): JSX.Element | null => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const { data, loading } = useGetUserSessionQuery({ fetchPolicy: 'no-cache', pollInterval: 5000 })

  const [onlineIndicator, setOnlineIndicator] = useState(0)
  const [insertUserSession] = useUpsertSessionMutation()

  const handleLogout = (): void => {
    deleteDefaultSubscriptionId()
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  useEffect(() => {
    insertUserSession().catch(console.error)

    setOnlineIndicator(
      window.setInterval(() => {
        insertUserSession().catch(console.error)
      }, 30000)
    )
    return () => {
      // Clean up
      clearInterval(onlineIndicator)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading && data) return <div>Loading...</div>

  const show = data?.unique_user_sessions[0]?.count > 1

  if (!show) return null

  return (
    <ModalContainer className={Styles.modal} show>
      <h3>Meerdere sessie's gedetecteerd</h3>
      <p>
        Op dit moment zijn onder hetzelfde account meerdere logins op verschillende apparaten gedetecteerd. Log uit op
        de andere apparaten om verder te kunnen gaan.
        <br />
        <br />
        Let op: Bij misbruik kan je account geblokkeerd worden.
      </p>

      <Button onClick={handleLogout}>{t('Log out')}</Button>
    </ModalContainer>
  )
}

export default SessionGuard
